export const fallbackPageConfig = {
  header: {
    menu: [
      {
        label: 'Home',
        href: '/'
      }
    ]
  },
  footer: {
    heading: 'Get in touch',
    address: {
      heading: 'Address',
      address: [
        {
          address_line: 'Spielfeld Digital Hub'
        },
        {
          address_line: 'Skalitzer Strasse 85/86'
        },
        {
          address_line: '10997 Berlin'
        },
        {
          address_line: 'Germany'
        }
      ]
    },
    email: {
      heading: 'General Inquiry',
      email: 'info@n3xtcoder.org'
    },
    follow_us: {
      heading: 'Social Media',
      socialChannels: []
    },
    site: {
      heading: 'N3xtcoder',
      links: [
        {
          label: 'Services',
          href: '/services'
        },
        {
          label: 'Tech Events',
          href: '/events'
        },
        {
          label: 'About',
          href: '/about'
        }
      ]
    },
    legal: {
      heading: 'Legal',
      links: [
        {
          label: 'Imprint',
          href: '/imprint'
        },
        {
          label: 'Privacy Policy',
          href: '/privacy'
        },
        {
          label: 'Cookie Policy',
          href: '/cookie'
        }
      ]
    }
  },
  metaData: {
    image: '',
    title: 'N3xtcoder',
    description: 'Something went wrong'
  },
  recommendedConfig: {
    headline: '',
    careerImage: '/media/n3xtcoder-team-5.jpg',
    eventsImage: '/media/03-hacksct18.sct18-900x1.jpg',
    blogImage: '/media/BMU_Hackthon010_small.jpg',
    ventureImage: '/media/00-n3xctcoder-wertewandel-workshop.jpg'
  }
}
