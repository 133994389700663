import type { NextPage, GetServerSideProps } from 'next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Section } from '../components'
import { Layout } from '../components/Layout'
import { fallbackPageConfig } from '../fixtures/fallback_page_config'
import { Locale } from '../types/shared_types'

const Custom404: NextPage = () => {
  const router = useRouter()
  const locale = router.locale as Locale

  const content = {
    en: {
      heading: `Sorry, this page doesn't exist`,
      sub: 'Return to the',
      homepage: 'Homepage'
    },
    de: {
      heading: 'Es tut uns leid, diese Seite existiert leider nicht',
      sub: 'Gehe zurück zur',
      homepage: 'Startseite'
    }
  }

  return (
    (<Layout config={fallbackPageConfig}>
      <Section className="my-40 text-center">
        <h1 className="my-20 scale-[2] md:scale-[4]">404</h1>
        <h3>{content[locale].heading}</h3>
        <p className="contentStyles my-10">
          {content[locale].sub} <Link href="/" legacyBehavior>{content[locale].homepage}</Link>
        </p>
      </Section>
    </Layout>)
  );
}

export default Custom404
